<template>
    <common-fuzzy :api="api" :cols="cols"></common-fuzzy>
</template>

<script>
    import CommonFuzzy from './CommonFuzzy'
    export default {
        components: {
            CommonFuzzy,
        },
        data() {
            return {
                api: {
                    page: 'setting/aimBaseDrugs/queryAimBaseDrugsListByKeyword'
                },
                cols: [
                    { key: 'comCode', value: '药品编码' },
                    { key: 'comName', value: '药品名称' },
                    { key: 'keyCode', value: '关键字编码' },
                    { key: 'keyName', value: '关键字名称' },
                ]
            }
        },
    }
</script>